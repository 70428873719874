import { graphql, useStaticQuery } from "gatsby";

const useThreeLastArticlesQuery = () => {
    // Get 3 most recent files from "/data/articles"
    const allMarkdownRemark = useStaticQuery(graphql`
        query ThreeLastArticles {
            allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/^.*\\/(articles)\\/.*$/"}},
                sort: {fields: frontmatter___date, order: DESC},
                limit: 3
            ) {
                nodes {
                    frontmatter {
                        slug
                        title
                        metaDescription
                        cover
                        date(formatString: "DD MM YYYY")
                    }
                }
            }
        }
    `);

    return ({ allMarkdownRemark });
};

export default useThreeLastArticlesQuery;
