import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import SvgBlockComponent from "./SvgBlockComponent";

const ServiceCardBlockComponent = ({ icon, subtitle, description, link }) => {
    const {t} = useTranslation();

    return (
        <Link className="d-flex h-100 p-3 p-md-4 align-items-center card service-card-wrapper" to={link}>
            <SvgBlockComponent className="icon" name={icon} />

            <h3 className="fw-light fs-3-integral ls-3 mb-3 card-header">{subtitle}</h3>
            <span className="fw-light fs-1-integral mb-3 card-body">{description}</span>

            <span className="fw-bolder fs-4 ls-2 text-primary font-bebas card-footer">{t("app.button.learnMore")}</span>
        </Link>
    );
};

export default ServiceCardBlockComponent;
