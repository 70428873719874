import React, { useEffect } from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Glide from '@glidejs/glide';

import SubHeaderBlockComponent from "../blocks/SubHeaderBlockComponent";

import ChevronLeftIcon from "../../assets/medias/svg/icons/icon-chevron-left.svg";
import ChevronRightIcon from "../../assets/medias/svg/icons/icon-chevron-right.svg";
import CnrsSVG from "../../assets/medias/svg/brands/cnrs.svg";
import DecathlonSVG from "../../assets/medias/svg/brands/decathlon.svg";
import EmmausSVG from "../../assets/medias/svg/brands/emmaus.svg";
import EngieSVG from "../../assets/medias/svg/brands/engie.svg";
import HappyKitsSVG from "../../assets/medias/svg/brands/happy-kits.svg";
import IPajeSVG from "../../assets/medias/svg/brands/i-paje.svg";
import KinderSVG from "../../assets/medias/svg/brands/kinder.svg";
import MichelinSVG from "../../assets/medias/svg/brands/michelin.svg";
import MyoohSVG from "../../assets/medias/svg/brands/myooh.svg";
import SorheaSVG from "../../assets/medias/svg/brands/sorhea.svg";
import SynlabSVG from "../../assets/medias/svg/brands/synlab.svg";
import VeoliaSVG from "../../assets/medias/svg/brands/veolia.svg";
import VitaprotechSVG from "../../assets/medias/svg/brands/vitaprotech.svg";

const ReferencesSectionComponent = () => {
    const {t} = useTranslation();

    useEffect(() => {
        const responsivenessConfig = {
            // Show 4 by default, for device's width higher than 992px
            992: { perView: 3 },
            768: { perView: 2 },
            576: { perView: 1 }
        };

        new Glide('.glide', {
            type: 'carousel',
            perView: 4,
            breakpoints: responsivenessConfig,
            animationDuration: 1000,    // In 'ms'
            autoplay: 2000,             // In 'ms'
            hoverpause: true,
            keyboard: false // Allow for changing slides with left and right keyboard arrows
        }).mount();
    }, []);

    return (    
        <section className="text-center py-section section-dark-theme">
            <div className="container">
                <SubHeaderBlockComponent title={t("app.title.ourReferences")} />
                <p className="mx-5 mb-4">{t("app.references.guideProjectsSuccessfullyMeetingNeeds")}</p>
        
                <div className="glide svg-dark">
                    <div className="glide__arrows" data-glide-el="controls">
                        <button type="button" className="glide__arrow glide__arrow--left" arial-label="previous" data-glide-dir="<">
                            <ChevronLeftIcon />
                        </button>
                    </div>

                    <div className="glide__track" data-glide-el="track">
                        <ul className="glide__slides">
                            <li className="glide__slide"><KinderSVG className="brand-logo" /></li>
                            <li className="glide__slide"><MichelinSVG className="brand-logo" /></li>
                            <li className="glide__slide"><EngieSVG className="brand-logo" /></li>
                            <li className="glide__slide"><DecathlonSVG className="brand-logo" /></li>
                            <li className="glide__slide"><IPajeSVG className="brand-logo" /></li>
                            <li className="glide__slide"><MyoohSVG className="brand-logo" /></li>
                            <li className="glide__slide"><SynlabSVG className="brand-logo" /></li>
                            <li className="glide__slide"><VeoliaSVG className="brand-logo" /></li>
                            <li className="glide__slide"><VitaprotechSVG className="brand-logo" /></li>
                            <li className="glide__slide"><SorheaSVG className="brand-logo" /></li>
                            <li className="glide__slide"><HappyKitsSVG className="brand-logo" /></li>
                            <li className="glide__slide"><CnrsSVG className="brand-logo" /></li>
                            <li className="glide__slide"><EmmausSVG className="brand-logo" /></li>
                        </ul>
                    </div>

                    <div className="glide__arrows" data-glide-el="controls">
                        <button type="button" className="glide__arrow glide__arrow--right" arial-label="next" data-glide-dir=">">
                        <ChevronRightIcon />
                        </button>
                    </div>
                </div>

            </div>
        </section>
    );
};

export default ReferencesSectionComponent;
