import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

const LearnMoreButtonBlockComponent = ({link, text}) => {
    return(
        <Link className="d-inline-block fw-bolder fs-4 ls-2 font-bebas nav-link" to={link}>{text} +</Link>
    );
};

export default LearnMoreButtonBlockComponent;
