import React from "react";
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import AboutSectionComponent from "../components/sections/AboutSectionComponent";
import BlogSectionComponent from "../components/sections/BlogSectionComponent";
import ReferencesSectionComponent from "../components/sections/ReferencesSectionComponent";
import ServicesSectionComponent from "../components/sections/ServicesSectionComponent";
import TechnologiesSectionComponent from "../components/sections/TechnologiesSectionComponent";

import BannerBlockComponent from "../components/blocks/BannerBlockComponent";
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";

const IndexPage = () => {
    const {t} = useTranslation();
    
    return (
        <DefaultLayoutComponent hasLogoAnimation>
            <AboutSectionComponent />
            
            <ServicesSectionComponent />
            <BannerBlockComponent catchPhrase={t("app.catchPhrase.talkTogetherNeed")} />

            <TechnologiesSectionComponent />

            <ReferencesSectionComponent />
            <BannerBlockComponent catchPhrase={t("app.catchPhrase.haveYouProject")} />

            <BlogSectionComponent />
            <BannerBlockComponent catchPhrase={t("app.catchPhrase.needInformation")} />
        </DefaultLayoutComponent>
    );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
