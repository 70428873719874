import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

import LearnMoreButtonBlockComponent from "../blocks/LearnMoreButtonBlockComponent";
import SubHeaderBlockComponent from "../blocks/SubHeaderBlockComponent";

const TechnologiesSectionComponent = () => {
    const {t} = useTranslation();

    return (
        <section className="container-fluid section-parallax-theme">
            <div className="row flex-wrap-reverse">
                <div className="col-12 col-md-6 section-light-theme">
                    {/* Workflow HERE */}
                </div>

                <div className="d-flex flex-column col-12 col-md-6 justify-content-center py-section text-center section-median-theme">
                    <SubHeaderBlockComponent title={t("app.title.ourExpertise")} />
                    <p className="mx-5 mb-5">{t("app.technologies.opensourceQualityDurabilitySolutions")}</p>
                    <LearnMoreButtonBlockComponent link="/technologies-web" text={t("app.button.seeTechnologiesCatalog")} />
                </div>
            </div>
        </section>
    );
};

export default TechnologiesSectionComponent;
    