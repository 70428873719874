import React, { useRef } from "react";

import FooterComponent from "../FooterComponent";
import HeaderWrapperComponent from "../headers/HeaderWrapperComponent";
import SEOComponent from "../SEOComponent";

const DefaultLayoutComponent = ({ children, hasLogoAnimation }) => {
    const pageContentRef = useRef();    // Used for the auto scroll button within the header

    return (
        <>
            <SEOComponent/>

            <HeaderWrapperComponent type="full" pageContentRef={pageContentRef} hasLogoAnimation={hasLogoAnimation} />

            <main ref={pageContentRef}>{ children }</main>

            <FooterComponent/>
        </>
    )
};

export default DefaultLayoutComponent;
