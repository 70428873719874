import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ServiceCardBlockComponent from "../blocks/ServiceCardBlockComponent";
import SubHeaderBlockComponent from "../blocks/SubHeaderBlockComponent";

const ServicesSectionComponent = () => {
    const {t} = useTranslation();

    return (
        <section className="text-center py-section section-dark-theme">
            <div className="container">
                <SubHeaderBlockComponent title={t("app.title.ourServices")} subtitle={t("app.services.conceiveDigitalSolutionsSuitingActivity")} />

                <div className="row justify-content-center">
                    <div className="col-sm-6 col-lg-4 mb-4"><ServiceCardBlockComponent icon="service-appli-web.svg" subtitle={t("app.services.webAppJobs")} description={t("app.services.cardDescription1")} link="/application-web" /></div>
                    <div className="col-sm-6 col-lg-4 mb-4"><ServiceCardBlockComponent icon="service-portail.svg" subtitle={t("app.services.collabPortalNets")} description={t("app.services.cardDescription2")} link="/portail-web-collaboratif" /></div>
                    <div className="col-sm-6 col-lg-4 mb-4"><ServiceCardBlockComponent icon="service-ecommerce.svg" subtitle={t("app.services.eCommerceSolutionIntegrated")} description={t("app.services.cardDescription3")} link="/e-commerce" /></div>
                    <div className="col-sm-6 col-lg-4 mb-4"><ServiceCardBlockComponent icon="service-appli-iot.svg" subtitle={t("app.services.iotApp")} description={t("app.services.cardDescription4")} link="/application-iot" /></div>
                    <div className="col-sm-6 col-lg-4 mb-4"><ServiceCardBlockComponent icon="service-appli-mobile.svg" subtitle={t("app.services.mobileAppCrossplatforms")} description={t("app.services.cardDescription5")} link="/application-mobile" /></div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSectionComponent;
