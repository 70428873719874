import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

import BlogCardBlockComponent from "../blocks/BlogCardBlockComponent";
import LearnMoreButtonBlockComponent from "../blocks/LearnMoreButtonBlockComponent";
import SubHeaderBlockComponent from "../blocks/SubHeaderBlockComponent";

import useThreeLastArticlesQuery from "../../data-loader/ThreeLastArticlesDataLoader";

const BlogSectionComponent = () => {
    const {t} = useTranslation();
    
    const ArticlesListParser = () => {
        const { allMarkdownRemark } = useThreeLastArticlesQuery();
        
        let articlesListArray = [];
        allMarkdownRemark.allMarkdownRemark.nodes.forEach((article, index) => {
            articlesListArray.push(
                <div key={index} className="col-12 col-md-6 col-lg-4 mb-4"><BlogCardBlockComponent content={article.frontmatter} /></div>
            );
        });

        return(
            <>{articlesListArray}</>
        );
    };

    return (
        <section className="text-center py-section section-light-theme">
            <div className="container">
                <SubHeaderBlockComponent title={t("app.title.ourNews")} subtitle={t("app.blog.headerSubtitle")} />

                <div className="row d-flex justify-content-center">
                    <ArticlesListParser />
                </div>

                <LearnMoreButtonBlockComponent link="/" text={t("app.button.seeBlog")} />
            </div>
        </section>
    );
};

export default BlogSectionComponent;
