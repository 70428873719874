import React from "react";

const SubHeaderBlockComponent = ({ title, subtitle }) => {
    return(
        <div className="w-100 mb-4 text-center">
            <h2 className="display-3 fs-5-integral ls-4">{title}</h2>
            
            <p className="fw-light ls-2">{subtitle}</p>
            
            <hr className="hr-short" />
        </div>
    );
};

export default SubHeaderBlockComponent;
