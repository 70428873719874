import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

import ImageBlockComponent from "./ImageBlockComponent";

const BlogCardBlockComponent = ({ content }) => {
    const {t} = useTranslation();

    return (
        <div className="h-100 text-start card blog-card-wrapper">
            <ImageBlockComponent src={content.cover} alt={content.title} />
            
            <div className="d-flex flex-column flex-fill p-1 p-md-2">
                <h3 className="h-100 fw-light fs-5 ls-3 card-header">{content.title}</h3>

                <span className="fw-light card-body">{content.metaDescription}</span>

                <hr className="m-1" />
                <div className="d-flex justify-content-between card-footer">
                    <Link className="fw-bolder fs-6 p-0 ls-2 font-bebas nav-link" to={content.slug}>{t("app.button.seeArticle")} +</Link>
                    <span className="fs-1-integral">{content.date}</span>
                </div>
            </div>
        </div>
    );
};

export default BlogCardBlockComponent;
